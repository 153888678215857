import {
  format,
  isSameYear,
  fromUnixTime,
  formatDistanceToNow,
} from 'date-fns';

export default {
  methods: {
    messageStamp(time, dateFormat = 'h:mm a') {
      const unixTime = fromUnixTime(time);
      return format(unixTime, dateFormat);
    },
    messageTimestamp(time, dateFormat = 'MMM d, yyyy') {
      const messageTime = fromUnixTime(time);
      const now = new Date();
      const messageDate = format(messageTime, dateFormat);
      if (!isSameYear(messageTime, now)) {
        return format(messageTime, 'LLL d y, h:mm a');
      }
      return messageDate;
    },
    dynamicTime(time) {
      const unixTime = fromUnixTime(time);
      return formatDistanceToNow(unixTime, { addSuffix: true });
    },
    dateFormat(time, dateFormat = 'MMM d, yyyy') {
      const unixTime = fromUnixTime(time);
      return format(unixTime, dateFormat);
    },
    shortTimestamp(time, withAgo = false) {
      // This function takes a time string and converts it to a short time string
      // with the following format: 1m, 1h, 1d, 1mo, 1y
      // The function also takes an optional boolean parameter withAgo
      // which will add the word "ago" to the end of the time string
      const suffix = withAgo ? ' ago' : '';
      const timeMappings = {
        'Alguns segundos atrás': 'now',
        'Um minuto atrás': `1m${suffix}`,
        'Uma hora atrás': `1h${suffix}`,
        'Um dia atrás': `1d${suffix}`,
        'Um mês atrás': `1mo${suffix}`,
        'Um ano atrás': `1y${suffix}`,
      };
      // Check if the time string is one of the specific cases
      if (timeMappings[time]) {
        return timeMappings[time];
      }
      const convertToShortTime = time
        .replace(/about|over|almost|/g, '')
        .replace(' minuto atrás', `m${suffix}`)
        .replace(' minutos atrás', `m${suffix}`)
        .replace(' hora atrás', `h${suffix}`)
        .replace(' horas atrás', `h${suffix}`)
        .replace(' dia atrás', `d${suffix}`)
        .replace(' dias atrás', `d${suffix}`)
        .replace(' mês atrás', `mo${suffix}`)
        .replace(' meses atrás', `mo${suffix}`)
        .replace(' ano atrás', `y${suffix}`)
        .replace(' anos atrás', `y${suffix}`);
      return convertToShortTime;
    },
  },
};

<template></template>

<script>
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import { mapGetters } from 'vuex';
import { BUS_EVENTS } from 'shared/constants/busEvents';

export default {
  mixins: [globalConfigMixin],

  data() {
    return {
      showNotification: !navigator.onLine,
    };
  },

  computed: {
    ...mapGetters({ globalConfig: 'globalConfig/get' }),
  },

  mounted() {
    window.addEventListener('offline', this.updateOnlineStatus);
    this.$emitter.on(BUS_EVENTS.WEBSOCKET_DISCONNECT, () => {
      // TODO: Remove this after completing the conversation list refetching
      // TODO: DIRTY FIX : CLEAN UP THIS WITH PROPER FIX, DELAYING THE RECONNECT FOR NOW
      // THE CABLE IS FIRING IS VERY COMMON AND THUS INTERFERING USER EXPERIENCE
      setTimeout(() => {
        this.updateOnlineStatus({ type: 'offline' });
      }, 4000);
    });
  },

  beforeDestroy() {
    window.removeEventListener('offline', this.updateOnlineStatus);
  },

  methods: {
    refreshPage() {
      window.location.reload();
    },

    closeNotification() {
      this.showNotification = false;
    },

    updateOnlineStatus(event) {
      if (event.type === 'offline') {
        this.showNotification = true;
      }
    },
  },
};
</script>

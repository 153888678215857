import { render, staticRenderFns } from "./Avatar.vue?vue&type=template&id=3855acd0&scoped=true&"
import script from "./Avatar.vue?vue&type=script&lang=js&"
export * from "./Avatar.vue?vue&type=script&lang=js&"
import style0 from "./Avatar.vue?vue&type=style&index=0&id=3855acd0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3855acd0",
  null
  
)

export default component.exports